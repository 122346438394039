
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Multiselect from '@vueform/multiselect';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-areas-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitSizeButton = ref<HTMLButtonElement | null>(null);
      const submitPropertyAgeButton = ref<HTMLButtonElement | null>(null);

      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ireAreasCreating'), [
          translate('ire'),
        ]);
      });
      const createdArea = ref<any | null>(null);
      // const createdSubCategory = ref<any | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const ireAreaSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'ire' });
      await store.dispatch(Actions.GET_All_IRE_V2_SIZE);
      await store.dispatch(Actions.GET_All_IRE_V2_PROPERTY_AGE);

      const areas = computed(() => store.getters.allListAreas);
      const selectedArea = ref<number>(areas.value[0].id);

      // await store.dispatch(Actions.GET_ALL_CCE_CATEGORIES);

      const sizeOptions = store.getters.ireV2Size.map((p) => ({
        id: p.id,
        isEnabled: false,
        isBase: false,
        title: p.title ? p.title : p.titleAr,
        percentage: null,
      }));

      const propertyAgeOptions = store.getters.ireV2PropertyAge.map((p) => ({
        id: p.id,
        title: p.title ? p.title : p.titleAr,
        price: null,
      }));

      // let sizeDescOptions: any = [];

      await store.dispatch(Actions.GET_All_IRE_V2_SIZE_DESC, 1);
      let sizeDescOptions = store.getters.ireV2SizeDesc;

      // console.log(sizeDescOptions);

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.areaId = selectedArea.value;
        values.customUpdatedAt = values.customUpdatedAt
          ? values.customUpdatedAt
          : undefined;
        const result = await store.dispatch(Actions.CREATE_IRE_V2_AREA, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdArea.value = result;

          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_IRE_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            // router.push({ name: 'ire-areas-listing' });

            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          });
        }
      };

      const onSubmitSizeOptions = async () => {
        if (sizeOptions.filter((obj) => obj.isBase === true).length > 1) {
          Swal.fire({
            text: translate('ONE_IRE_BASE_SIZE_OPTIONS'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else if (sizeOptions.every((obj) => obj.isBase != true)) {
          Swal.fire({
            text: translate('MUST_ADDED_IRE_BASE_SIZE_OPTIONS'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          const sizeOptionsValue = sizeOptions.reduce(
            (previousValue, currentValue) => {
              if (currentValue.isEnabled) {
                previousValue.push({
                  ireSizeId: currentValue.id,
                  isBase: currentValue.isBase ? true : false,
                  value: currentValue.value ? currentValue.value : null,
                  percentage: currentValue.percentage,
                });
              }
              return previousValue;
            },
            []
          );

          const payload = {
            ireAreaId: createdArea.value?.id,
            sizeOptionsValue,
          };

          if (submitSizeButton.value) {
            // eslint-disable-next-line
            submitSizeButton.value!.disabled = true;
            submitSizeButton.value.setAttribute('data-kt-indicator', 'on');
          }

          await store.dispatch(
            Actions.UPDATE_IRE_SIZE_CONFIG_OPTIONS_V2,
            payload
          );
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
            //Deactivate indicator
            submitSizeButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitSizeButton.value!.disabled = false;
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_ADDED_IRE_SIZE_OPTIONS'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              //Deactivate indicator
              submitSizeButton.value?.removeAttribute('data-kt-indicator');
              // eslint-disable-next-line
              submitSizeButton.value!.disabled = false;
            });
          }
        }
      };

      const onSubmitAgeOptions = async () => {
        const propertyAgeOptionsValue = propertyAgeOptions.reduce(
          (previousValue, currentValue) => {
            previousValue.push({
              irePropertyAgeId: currentValue.id,
              percentage: currentValue.price,
            });
            return previousValue;
          },
          []
        );

        const payload = {
          ireAreaId: createdArea.value?.id,
          propertyAgeOptionsValue,
        };

        if (submitPropertyAgeButton.value) {
          // eslint-disable-next-line
          submitPropertyAgeButton.value!.disabled = true;
          submitPropertyAgeButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(
          Actions.UPDATE_IRE_PROPERTY_AGE_CONFIG_OPTIONS,
          payload
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitPropertyAgeButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitPropertyAgeButton.value!.disabled = false;
        } else {
          // await store.dispatch(
          //   Actions.GET_All_IRE_V2_SIZE_DESC,
          //   createdArea.value.id
          // );
          // sizeDescOptions = store.getters.ireV2SizeDesc;

          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_IRE_PROPERTY_AGE_OPTIONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitPropertyAgeButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitPropertyAgeButton.value!.disabled = false;
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        areas,
        onSubmitCreate,
        submitButton,
        translate,
        ireAreaSchema,
        goBack,
        can,
        selectedArea,
        sizeOptions,
        onSubmitSizeOptions,
        propertyAgeOptions,
        sizeDescOptions,
        createdArea,
        onSubmitAgeOptions,
        submitPropertyAgeButton,
        submitSizeButton,
      };
    },
  });
